import {
  mapQueryDocAnalyticsFilesCount,
  mapQueryDocAnalyticsFilesList,
  mapQueryDocAnalyticsSitesFilter,
  queryDocAnalyticsFilesCount,
  queryDocAnalyticsFilesList,
  queryDocAnalyticsSitesFilter
} from './queries'
import { DATA_SOURCE_ID, PAGE, SEARCH_QUERY } from '../../constants'
import { FilterParams } from '../../interfaces'
import apiService from '../../services/api/apiService'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

type DocAnalyticsState = {
  docAnalyticsStatus: DocAnalyticsStatus
  filesList: DocAnalyticsFilesObject[]
  filesCount: number
  sitesFilterList: SitesFilterObj[]
  fileTypeFilterList: FileTypeFilterObj[]
  fileSizeWidget: WidgetFileSizeObj[]
  lastUpdatedWidget: LastUpdatedWidgetObj[]
}

export enum STATUS {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  AVAILABLE = 'AVAILABLE'
}

export type ExtendedStatus = STATUS | '' | '-'
export interface DocAnalyticsStatus {
  lastUpdatedOn: number
  status: ExtendedStatus
}
export interface FetchDocAnalyticsStatusParams {
  [DATA_SOURCE_ID]?: string
}

export interface TriggerDocAnalyticsParams {
  [DATA_SOURCE_ID]?: string
}

//Files
export interface FetchDocAnalyticsFilesListParams {
  [DATA_SOURCE_ID]?: string
  filters?: FilterParams
  [PAGE]: number
}

export type DocAnalyticsFilesObject = {
  id: string
  name: string
  extension: string
  size: number
  skipped: boolean
  skipReason?: string
  checksum?: string
  lastModifiedTimestamp: string
  createdTimestamp?: string
  objectLink: string
}

export type DocAnalyticsFilesResponseType = {
  list: DocAnalyticsFilesObject[]
}

export type DocAnalyticsFilesCountResponseType = {
  total: number
}

//Sites Filter
export interface SitesFilterParams {
  [DATA_SOURCE_ID]: string
  [SEARCH_QUERY]?: string
}

export type SitesFilterObj = {
  siteId: string
  name: string
}

export type SitesFilterResponseType = {
  list: SitesFilterObj[]
}

//File Type filter
export interface FileTypeFilterParams {
  [DATA_SOURCE_ID]: string
  skipped?: boolean
}

export interface DocAnalyticsWidgetParams {
  [DATA_SOURCE_ID]: string
  skipped?: boolean
}

export type FileTypeFilterObj = {
  extension: string
  count: number
}

export interface ISizeRange {
  lt: number
  gte: number
}

export type WidgetFileSizeObj = {
  sizeRange: ISizeRange
  count: number
}

export type LastUpdatedWidgetObj = {
  lastModifiedDateCategory: string
  count: number
}

//Initial Reducer State
const initialState: DocAnalyticsState = {
  docAnalyticsStatus: {
    lastUpdatedOn: -1,
    status: '-'
  },
  filesList: [],
  filesCount: 0,
  sitesFilterList: [],
  fileTypeFilterList: [],
  fileSizeWidget: [],
  lastUpdatedWidget: []
}

export const ACTION_FETCH_DOC_ANALYTICS_STATUS = 'docAnalytics/status'
export const fetchDocAnalyticsStatus = createAsyncThunk(
  ACTION_FETCH_DOC_ANALYTICS_STATUS,
  async (params: FetchDocAnalyticsStatusParams) => {
    const result = await apiService.getDocAnalyticsStatus(params)
    return result
  }
)

export const ACTION_RUN_DOC_ANALYTICS = 'docAnalytics/trigger'
export const triggerDocAnalytics = createAsyncThunk(
  ACTION_RUN_DOC_ANALYTICS,
  async (params: TriggerDocAnalyticsParams) => {
    const result = await apiService.runDocAnalytics(params)
    return result
  }
)

export const ACTION_FETCH_DOC_ANALYTICS_FILES_LIST = 'docAnalytics/fetchFilesList'
export const fetchDocAnalyticsFilesList = createAsyncThunk(
  ACTION_FETCH_DOC_ANALYTICS_FILES_LIST,
  async (params: FetchDocAnalyticsFilesListParams) => {
    const resultRaw = await graphqlService.execute(queryDocAnalyticsFilesList(params))
    return mapQueryDocAnalyticsFilesList(resultRaw)
  }
)

export const ACTION_FETCH_DOC_ANALYTICS_FILES_COUNT = 'docAnalytics/fetchFilesCount'
export const fetchDocAnalyticsFilesCount = createAsyncThunk(
  ACTION_FETCH_DOC_ANALYTICS_FILES_COUNT,
  async (params: FetchDocAnalyticsFilesListParams) => {
    const resultRaw = await graphqlService.execute(queryDocAnalyticsFilesCount(params))
    return mapQueryDocAnalyticsFilesCount(resultRaw)
  }
)

export const ACTION_FETCH_SITES_FILTER = 'docAnalytics/filter/sites'
export const fetchSitesFilter = createAsyncThunk(
  ACTION_FETCH_SITES_FILTER,
  async (params: SitesFilterParams) => {
    const resultRaw = await graphqlService.execute(queryDocAnalyticsSitesFilter(params))
    return mapQueryDocAnalyticsSitesFilter(resultRaw)
  }
)

export const ACTION_FETCH_FILE_TYPE_FILTER = 'docAnalytics/filter/fileType'
export const fetchFileTypeFilter = createAsyncThunk(
  ACTION_FETCH_FILE_TYPE_FILTER,
  async (params: FileTypeFilterParams) => {
    const result = await apiService.getDocAnalyticsFileTypeFilterList(params)
    return result
  }
)

export const ACTION_FETCH_WIDGET_FILE_SIZE = 'docAnalytics/widget/fileSize'
export const fetchWidgetFileSize = createAsyncThunk(
  ACTION_FETCH_WIDGET_FILE_SIZE,
  async (params: DocAnalyticsWidgetParams) => {
    const result = await apiService.getDocAnalyticsFileSizeList(params)
    return result
  }
)

export const ACTION_FETCH_WIDGET_LAST_UPDATED = 'docAnalytics/fetch/lastUpdated'
export const fetchWidgetLastUpdated = createAsyncThunk(
  ACTION_FETCH_WIDGET_LAST_UPDATED,
  async (params: DocAnalyticsWidgetParams) => {
    const result = await apiService.getDocAnalyticsLastUpdatedList(params)
    return result
  }
)

const docAnalyticsSlice = createSlice({
  name: 'docAnalytics',
  initialState,
  reducers: {
    resetDocAnalyticsStatus: (state) => {
      state.docAnalyticsStatus = initialState.docAnalyticsStatus
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocAnalyticsStatus.fulfilled, (state, { payload }) => {
      state.docAnalyticsStatus = payload
    })
    builder.addCase(fetchDocAnalyticsFilesList.fulfilled, (state, action) => {
      state.filesList = action.payload.list
    })
    builder.addCase(fetchDocAnalyticsFilesCount.fulfilled, (state, action) => {
      state.filesCount = action.payload.total
    })
    builder.addCase(fetchSitesFilter.fulfilled, (state, action) => {
      state.sitesFilterList = action.payload.list
    })
    builder.addCase(fetchFileTypeFilter.fulfilled, (state, { payload }) => {
      state.fileTypeFilterList = payload
    })
    builder.addCase(fetchWidgetFileSize.fulfilled, (state, { payload }) => {
      state.fileSizeWidget = payload
    })

    builder.addCase(fetchWidgetLastUpdated.fulfilled, (state, { payload }) => {
      state.lastUpdatedWidget = payload
    })
  }
})

export const { resetDocAnalyticsStatus } = docAnalyticsSlice.actions
export default docAnalyticsSlice.reducer
