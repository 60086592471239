import type {
  Document,
  Email,
  DataSource,
  UserEntity,
  AnnotationsWidgetData,
  Classification,
  Identifier,
  FileTypeWidgetData,
  GetTextLabels,
  GetTextLabelsParams,
  UserEntityBeingShared,
  DataLocation,
  ITotalChannelsUsers,
  IViolations,
  ConnectedApps,
  AlertInfoItem,
  NamedTimeSeriesItem,
  WidgetAnnotationsQueryParams,
  GDriveSummary,
  GDriveWidget,
  GDriveWidgetQueryParams,
  AnomaliesInfoItem,
  AuditTrailItem,
  SentimentAnalysisItem,
  GDriveUsersWidgets,
  User,
  GDriveUsersWidgetsQueryParams,
  GDriveGroupsWidgetsQueryParams,
  GDriveGroupsWidgets,
  Group,
  GDriveSummaryQueryParams,
  Location,
  SlackWidgetQueryParams,
  SlackWorkspaceWidget,
  SlackMembersWidgets,
  SlackChannelsWidgets,
  ImMessage,
  Violation,
  CreatedTemplate,
  GMailSummaryQueryParams,
  GMailSummary,
  GMailUsersWidgetQueryParams,
  GMailUsersWidget,
  ConversationMessage,
  AnnotationFeedbackParams,
  DocumentClassCards,
  DocumentClassCardsParams,
  ElasticSearchIndexFieldParams,
  ElasticSearchIndexFieldUpdateParams,
  OneDriveSummaryQueryParams,
  OneDriveSummary,
  ConnectionStats,
  SharePointSummary,
  AWSS3Summary,
  AWSS3SummaryQueryParams,
  OutlookSummaryQueryParams,
  OutlookSummary,
  OutlookUsersWidgetQueryParams,
  OutlookUsersWidget,
  MessagesSummary,
  GetAnonymizeText,
  onDemandResults,
  LBVersionDetails,
  AuditLogsResponse,
  AuditLogsMapping,
  TestConnectionDetails,
  DataSourceV2
} from './apiTypes'
import request, { axiosInstance, requestWithErrorData } from './apiRequest'
import { API_ERROR_STATUS } from './apiStatuses'
import {
  FILTER_COUNT_HEADER,
  LIMIT,
  OFFSET,
  LIMIT_DEFAULT,
  TOTAL_FILES_HEADER,
  SYNCED_FILES_HEADER,
  DATA_SOURCE_ID,
  TOTAL_COUNT_HEADER,
  PAGE,
  DATA_SOURCE_TYPE_API_MAP,
  DATA_SOURCE_TYPES,
  DownloadReportFormats,
  DownloadReportTypes,
  CHANNEL_TYPES
} from '../../constants'
import serviceConfig from '../../apiConfig'
import {
  FileTotalHeaders,
  IGetDocumentsParams,
  IGetEmailParams,
  IGetEntitiesParams,
  IGetGroupsParams,
  IGetUsersParams,
  IGetResidenciesParams,
  IGetLocationsParams,
  IGetViolationsParams,
  IGetMessagesParams,
  IGetIdentifiersParams,
  IGetTemplatesParams,
  IResidency,
  DownloadListParams,
  DownloadPdfParams
} from '../../interfaces'
/**Mock data import */
import { classificationTemplates } from '../../__mocks__/classificationTemplates'
import { classificationCountries } from '../../__mocks__/classificationCountries'
import { connectedApps } from '../../__mocks__/connectedApps'
import { alertsList, alertsChart } from '../../__mocks__/alerts'
import { auditTrailList } from '../../__mocks__/auditTrail'
import { anomaliesList, anomaliesChart } from '../../__mocks__/anomalies'
import { sentimentAnalysisList } from '../../__mocks__/sentimentAnalysis'
import { memCache } from '../../utils/cache'
import { ColumnClassificationsParams } from '../../features/columns/columnsSlice'
import {
  setServerError,
  Token_Params,
  logout as logoutAction
} from '../../features/login/loginSlice'
import {
  AttributeWidgetByEntityItem,
  IGetAttributesParams
} from '../../features/attributes/attributesSlice'
import {
  AwsS3Bucket,
  AwsS3BucketsNotificationsParams,
  AwsS3BucketsParams,
  InstancesByDatasourceWidgetItem,
  ExchangeDatasourceParams,
  JiraProject,
  JiraProjectParams,
  MailMemberGroup,
  ScanSettingsSearchParams,
  SharePointSite,
  SlackChannel,
  SlackChannelParams,
  AzureBlobContainerParams,
  AzureBlobContainer,
  AzureBlobStorageAccountParams,
  AzureBlobStorageAccount,
  AzureBlobSubscription,
  AzureBlobSubscriptionParams,
  MSTeamsTeam,
  OrgDomain,
  MSTeamsParams
} from '../../features/dataSources/dataSourcesSlice'
import { IUser, UpdateUserParams } from '../../features/users/usersSlice'
import { IGetAlertsListParams } from '../../features/alerts/alertsSlice'
import { SmtpConfiguration } from '../../features/ConfigureSmtp/smtpSlice'
import { IdentityTableParams } from '../../features/tableRelationships/tableRelationshipsSlice'
import { DsrFormUploadedFile } from '../../features/dsr/dsrForms/dsrFormsSlice'
import { ConnectionStatsParams } from '../../features/databases/databasesSlice'
import { AuthProvider } from '../../features/authProvider/authProviderSlice'
import {
  DownloadJsonParams,
  CrateErasedEntityParams,
  ErasedEntity,
  UpdateErasedEntityParams
} from '../../features/entities/entitiesSlice'
import { Drive, DriveTypes, Template } from '../graphqlSchemaTypes'
import {
  AttributeDetectedSummary,
  UploadTicketAttributesParams
} from '../../features/dsr/tickets/dsrTicketsSlice'
import {
  downloadBlobFile,
  openBlobInNewTab,
  transformFileToDataUrl
} from '../../utils/documentUtil'
import { CookieConsentBanner } from '../../features/cookieConsent/banners/bannersSlice'
import {
  UpdateCookieConsentRegionParams,
  ConsentLog,
  Cookie,
  CookieCategory,
  CookieConsentDomain,
  CookieConsentRegionBannerIdsParams,
  CookieConsentRegionParams,
  FetchDomainConsentLogParams,
  FetchDomainCookiesParams,
  UpdateCookieConsentHostingSettingsParams,
  CookieConsentDomainValidationParams,
  CookieConsentS3TestConnectionParams
} from '../../features/cookieConsent/domains/domainsSlice'
import { ReportConfiguration } from '../../features/ConfigureReport/reportSlice'
import { getFilenameFromHeaders } from '../../utils/httpUtil'
import {
  DocAnalyticsWidgetParams,
  FetchDocAnalyticsStatusParams,
  FileTypeFilterParams,
  TriggerDocAnalyticsParams
} from '../../features/docAnalytics/docAnalyticsSlice'
import { RegexValidationResponse } from '../../features/attributes/settings/types'
import { SYSTEM_DATA_TYPES } from '../../features/policies/policySettings/types'
import { DocumentLabelProvider } from '../../features/documentLabelsV2/types'
import { store } from '../..'
import { stringify } from 'query-string'
import { AxiosResponseHeaders } from 'axios'

const cache = memCache()
const getUserInfo = async (): Promise<any> => {
  try {
    const response = await request({
      url: serviceConfig.API_USER_AUTH_INFO.url,
      timeout: serviceConfig.API_USER_AUTH_INFO.timeout
    })
    return response.data
  } catch (error: any) {
    store.dispatch(logoutAction())
    return API_ERROR_STATUS.UNHANDLED_ERROR
  }
}
const setUserLocale = async (language: string, userID: string): Promise<any> => {
  const response = await request({
    method: 'PATCH',
    data: { language },
    url: serviceConfig.API_USER_SET_LANGUAGE.url + userID,
    timeout: serviceConfig.API_USER_AUTH_INFO.timeout
  })
  return response.data
}
const getToken = async (data: Token_Params): Promise<any> => {
  try {
    const response = await request({
      method: 'POST',
      url: serviceConfig.API_AUTH_TOKEN.url,
      timeout: serviceConfig.API_AUTH_TOKEN.timeout,
      data
    })
    return response.data
  } catch (error: any) {
    store.dispatch(logoutAction())
    return API_ERROR_STATUS.UNHANDLED_ERROR
  }
}
const logout = async (): Promise<any> => {
  try {
    const response = await request({
      url: serviceConfig.API_LOGOUT.url,
      timeout: serviceConfig.API_LOGOUT.timeout
    })
    return response.data
  } catch (error: any) {
    store.dispatch(setServerError(true))
    return API_ERROR_STATUS.UNHANDLED_ERROR
  }
}

const getAuthProviders = async (): Promise<AuthProvider[]> => {
  const response = await request({
    url: serviceConfig.API_AUTH_PROVIDERS.url,
    timeout: serviceConfig.API_AUTH_PROVIDERS.timeout,
    method: 'GET'
  })
  return response.data
}
const getAuthProviderByName = async (name: string): Promise<AuthProvider> => {
  const response = await request({
    url: `${serviceConfig.API_AUTH_PROVIDERS.url}/${name}`,
    timeout: serviceConfig.API_AUTH_PROVIDERS.timeout,
    method: 'GET'
  })
  return response.data
}
const createAuthProvider = async (data: AuthProvider): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_AUTH_PROVIDERS.url,
    timeout: serviceConfig.API_AUTH_PROVIDERS.timeout,
    data
  })
  return response.data
}

const updateAuthProvider = async (data: AuthProvider): Promise<void> => {
  const { name } = data
  delete data.id
  delete data.name
  const response = await request({
    method: 'PATCH',
    url: `${serviceConfig.API_AUTH_PROVIDERS.url}/${name}`,
    timeout: serviceConfig.API_AUTH_PROVIDERS.timeout,
    data
  })
  return response.data
}

const deleteAuthProvider = async (name: string): Promise<void> => {
  const response = await request({
    method: 'DELETE',
    url: `${serviceConfig.API_AUTH_PROVIDERS.url}/${name}`,
    timeout: serviceConfig.API_AUTH_PROVIDERS.timeout
  })
  return response.data
}

const getDocumentById = async (documentId: string): Promise<Document> => {
  const response = await request({
    url: `${serviceConfig.API_DOCUMENTS.url}/${documentId}?anonymize=false`,
    timeout: serviceConfig.API_DOCUMENTS.timeout
  })

  return response.data
}

const getFileByDocumentId = async (documentId: string, date?: string): Promise<any> => {
  const cachedImage = await cache.get(documentId)
  if (cachedImage != null) {
    if (date) {
      const time = Date.parse(date)
      if (time < cachedImage.timestamp) {
        return cachedImage?.data
      }
    } else {
      return cachedImage?.data
    }
  }
  const response = await request({
    url: `${serviceConfig.API_FILES.url}/${documentId}?anonymize=false`,
    timeout: serviceConfig.API_FILES.timeout,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' }
  })
  //convert doc to docx since doc is not supported for preview in browser
  if (response.data.type === 'application/msword') {
    const form = new FormData()
    form.append('file', response.data)
    const docxResponse = await axiosInstance.post(`/doc-to-docx`, form, {
      timeout: serviceConfig.API_FILES.timeout,
      responseType: 'blob',
      headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'multipart/form-data' }
    })

    await cache.set(documentId, {
      data: docxResponse.data,
      timestamp: Date.now()
    })
    return docxResponse.data
  } else {
    await cache.set(documentId, {
      data: response.data,
      timestamp: Date.now()
    })
    return response.data
  }
}

const postTemplateFile = async (data: FormData): Promise<CreatedTemplate> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_FILES_ANNOTATE.url}`,
    timeout: serviceConfig.API_FILES_ANNOTATE.timeout,
    data
  })

  return response.data
}

const postDsrFile = async (data: FormData): Promise<DsrFormUploadedFile> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_FILES_ANNOTATE.url}`,
    timeout: serviceConfig.API_FILES_ANNOTATE.timeout,
    data
  })

  return response.data
}

const putDocumentById = async (data: Document): Promise<Document> => {
  const response = await request({
    method: 'PUT',
    url: `${serviceConfig.API_DOCUMENTS.url}/${data.id}`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    data
  })
  return response.data
}

const postTextLabels = async (data: GetTextLabelsParams): Promise<GetTextLabels> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_TEXT_LABELS.url,
    timeout: serviceConfig.API_TEXT_LABELS.timeout,
    data
  })
  return response.data
}

const deleteTemplate = async (id: string): Promise<{ message: string; id: string }> => {
  const response = await request({
    method: 'DELETE',
    url: serviceConfig.API_TEMPLATES.url + '/' + id
  })

  return { ...response.data, id }
}

const getMailMembers = async (params: ScanSettingsSearchParams): Promise<MailMemberGroup[]> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_DATA_SOURCE_MEMBERS.url,
    data: params
  })
  return response.data
}

const getMailGroups = async (params: ScanSettingsSearchParams): Promise<MailMemberGroup[]> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_DATA_SOURCE_GROUPS.url,
    data: params
  })
  return response.data
}

const getDrives = async (
  params: ScanSettingsSearchParams
): Promise<(Drive & { driveType: DriveTypes })[]> => {
  const typeParam = params.type ? '?driveType=' + params.type : ''
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_DATA_SOURCES_DRIVES.url + typeParam,
    data: params
  })
  return response.data
}

const getSharePointSites = async (params: ScanSettingsSearchParams): Promise<SharePointSite[]> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_DATA_SHARE_POINT_SITES.url,
    data: params
  })
  return response.data
}

const getOrgDomains = async (
  params: ScanSettingsSearchParams
): Promise<{ domains: OrgDomain[] }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_ORG_DOMAINS.url,
    data: params
  })
  return response.data
}

const getTemplates = async (
  filters: IGetTemplatesParams
): Promise<{ list: Template[]; total: number }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_TEMPLATES.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return { list: response.data, total }
}

const getTemplateById = async (templateId: string): Promise<Template> => {
  const response = await request({ url: `${serviceConfig.API_TEMPLATES.url}/${templateId}` })

  return response.data
}

const getIdentifiers = async (
  filters: IGetIdentifiersParams
): Promise<{ list: Identifier[]; total: number }> => {
  let baseQueryParams = {}

  if (filters.page) {
    baseQueryParams = {
      [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
      [LIMIT]: LIMIT_DEFAULT
    }
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_IDENTIFIERS.url}?${stringify(queryParams)}`
  })

  const total = +response?.headers[TOTAL_COUNT_HEADER] || 0
  return { list: response.data, total }
}

const getDataSourceById = async (
  id: string
): Promise<{ data: DataSource; files: FileTotalHeaders }> => {
  const response = await request({
    url: `${serviceConfig.API_DATA_SOURCES.url}/${id}`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })

  const totalFiles = +response?.headers[TOTAL_FILES_HEADER] || 0
  const totalFilesSynced = +response?.headers[SYNCED_FILES_HEADER] || 0

  return {
    files: {
      totalCount: totalFiles,
      syncedCount: totalFilesSynced
    },
    data: response.data
  }
}

const getDataAnalyticsById = async (id: string): Promise<any> => {
  const response = await request({
    url: `${serviceConfig.API_DATA_SOURCES.url}/${id}/analytics`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })
  return response.data.analytics_output
}

const getElasticSearchIndicesById = async (id: string): Promise<{ data: string[] }> => {
  const response = await request({
    url: `${serviceConfig.API_ELASTIC_SEARCH_INDICES.url}?${stringify({ datasourceId: id })}`,
    timeout: serviceConfig.API_ELASTIC_SEARCH_INDICES.timeout
  })
  return {
    data: response.data
  }
}

const getElasticSearchIndexFieldsById = async (
  params: ElasticSearchIndexFieldParams
): Promise<{
  fields: {
    indexFields: string[]
    currentTimestampField: string
  }
}> => {
  const response = await request({
    url: `${serviceConfig.API_ELASTIC_SEARCH_INDICES.url}/${params.index}/fields?${stringify({
      datasourceId: params.datasourceId
    })}`,
    timeout: serviceConfig.API_ELASTIC_SEARCH_INDICES.timeout
  })
  return {
    fields: response.data
  }
}

const updateElasticSearchIndexFieldsById = async (
  data: ElasticSearchIndexFieldUpdateParams
): Promise<{ message: string }> => {
  const response = await request({
    url: `${serviceConfig.API_ELASTIC_SEARCH_INDICES.url}/timestamp-fields`,
    timeout: serviceConfig.API_ELASTIC_SEARCH_INDICES.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const deleteDataSourceById = async (id: string): Promise<string> => {
  await request({
    url: `${serviceConfig.API_DATA_SOURCES.url}/${id}`,
    method: 'DELETE',
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })
  return id
}

const postDatasource = async (
  data: DataSource
): Promise<{ Id: string; message: string; data: string }> => {
  const response = await request({
    url: serviceConfig.API_DATA_SOURCES.url,
    timeout: serviceConfig.API_DATA_SOURCES.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const postDatasourceV2 = async (
  data: DataSourceV2
): Promise<{ Id: string; message: string; data: string }> => {
  const response = await request({
    url: serviceConfig.API_DATA_SOURCES.url,
    timeout: serviceConfig.API_DATA_SOURCES.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const postAnonymizeText = async (data: GetAnonymizeText): Promise<onDemandResults> => {
  const response = await request({
    url: serviceConfig.API_FILES_ANONYMIZE_TEXT.url,
    timeout: serviceConfig.API_FILES_ANONYMIZE_TEXT.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const postAnonymize = async (data: GetAnonymizeText): Promise<any> => {
  const response = await request({
    url: serviceConfig.API_FILES_ANONYMIZE.url,
    timeout: serviceConfig.API_FILES_ANONYMIZE.timeout,
    method: 'POST',
    data,
    responseType: 'blob'
  })
  downloadBlobFile(response.data, data?.object_name || '')
}

const postExchangeDatasource = async ({
  srcDatasourceId,
  name,
  folderId
}: ExchangeDatasourceParams): Promise<{ Id: string; message: string; data: string }> => {
  const response = await request({
    url: serviceConfig.API_EXCHANGE_DATA_SOURCES.url,
    timeout: serviceConfig.API_EXCHANGE_DATA_SOURCES.timeout,
    method: 'POST',
    data: {
      source_datasource_id: srcDatasourceId,
      folder: folderId,
      datasource_name: name
    }
  })
  return response.data
}

const putDatasourceById = async (
  darasourceId: string,
  data: DataSource
): Promise<{ message: string }> => {
  const response = await request({
    method: 'PUT',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${darasourceId}`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout,
    data
  })
  return response.data
}

const patchDatasourceById = async (
  darasourceId: string,
  data: DataSource
): Promise<{ message: string }> => {
  const response = await request({
    method: 'PATCH',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${darasourceId}`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout,
    data
  })
  return response.data
}

const patchDatasourceByIdV2 = async (
  darasourceId: string,
  data: DataSourceV2
): Promise<{ message: string }> => {
  const response = await request({
    method: 'PATCH',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${darasourceId}`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout,
    data
  })
  return response.data
}

const deleteProjectById = async (id: string): Promise<string> => {
  await request({
    url: `${serviceConfig.API_GENERIC_DS_PROJECTS.url}/${id}`,
    method: 'DELETE',
    timeout: serviceConfig.API_GENERIC_DS_PROJECTS.timeout
  })
  return id
}

const testConnection = async (
  data: DataSource
): Promise<{ message: string; details: TestConnectionDetails }> => {
  const response = await requestWithErrorData({
    url: `${serviceConfig.API_DIAGNOSTICS.url}/testconnection`,
    timeout: serviceConfig.API_DIAGNOSTICS.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const fetchOauthRedirectUrl = async (
  data: DataSource,
  state?: string
): Promise<{ redirect_url: string }> => {
  const response = await request({
    url: serviceConfig.API_DATA_SOURCES_OAUTH.url,
    timeout: serviceConfig.API_DATA_SOURCES_OAUTH.timeout,
    method: 'POST',
    data,
    params: state ? { state } : {}
  })
  return response.data
}

const testSmtpConfiguration = async (data: SmtpConfiguration): Promise<{ message: string }> => {
  const response = await request({
    url: serviceConfig.API_SMTP_CONNECTION_TEST.url,
    timeout: serviceConfig.API_SMTP_CONNECTION_TEST.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const saveSmtpConfiguration = async (data: SmtpConfiguration): Promise<{ message: string }> => {
  const response = await request({
    url: serviceConfig.API_SMTP_CONNECTION.url,
    timeout: serviceConfig.API_SMTP_CONNECTION.timeout,
    method: 'POST',
    data
  })
  return response.data
}
const saveReportConfiguration = async (data: ReportConfiguration): Promise<{ message: string }> => {
  const response = await request({
    url: serviceConfig.API_REPORT_CONFIG.url,
    timeout: serviceConfig.API_REPORT_CONFIG.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const deleteReportConfiguration = async (id: string): Promise<string> => {
  const response = await request({
    method: 'DELETE',
    url: `${serviceConfig.API_REPORT_CONFIG.url}/${id}`,
    timeout: serviceConfig.API_REPORT_CONFIG.timeout,
    data: { id }
  })
  return response.data
}

const getReportConfiguration = async (): Promise<ReportConfiguration[]> => {
  const response = await request({
    url: serviceConfig.API_REPORT_CONFIG.url,
    timeout: serviceConfig.API_REPORT_CONFIG.timeout,
    method: 'GET'
  })
  return response.data
}
const getSmtpConfiguration = async (): Promise<SmtpConfiguration> => {
  const response = await request({
    url: serviceConfig.API_SMTP_CONNECTION.url,
    timeout: serviceConfig.API_SMTP_CONNECTION.timeout,
    method: 'GET'
  })
  return response.data
}

const postConnectionStats = async (data: ConnectionStatsParams): Promise<ConnectionStats> => {
  const response = await request({
    url: `${serviceConfig.API_DIAGNOSTICS.url}/connection-stats`,
    timeout: serviceConfig.API_DIAGNOSTICS.timeout,
    method: 'POST',
    data
  })
  return response.data
}
const postBigQueryProjects = async (
  data: ConnectionStatsParams
): Promise<Array<{ projectId: string; datasets: Array<{ datasetId: string }> }>> => {
  const response = await request({
    url: `${serviceConfig.API_DIAGNOSTICS.url}/connection-stats`,
    timeout: serviceConfig.API_DIAGNOSTICS.timeout,
    method: 'POST',
    data
  })
  return response.data
}
const postLookerProjects = async (
  data: ConnectionStatsParams
): Promise<Array<{ project_name: string; models: Array<{ model_name: string }> }>> => {
  const response = await request({
    url: `${serviceConfig.API_DIAGNOSTICS.url}/connection-stats`,
    timeout: serviceConfig.API_DIAGNOSTICS.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const postDatabricksCatalogue = async (
  data: ConnectionStatsParams
): Promise<{
  warehouse: Array<{ warehouse_name: string; warehouse_id: string }>
  database: Array<{ catalog_name: string; databases: Array<string> }>
}> => {
  const response = await request({
    url: `${serviceConfig.API_DIAGNOSTICS.url}/connection-stats`,
    timeout: serviceConfig.API_DIAGNOSTICS.timeout,
    method: 'POST',
    data
  })
  return response.data
}

const getVersionDetails = async (): Promise<LBVersionDetails> => {
  const response = await request({
    url: serviceConfig.API_VERSION_DETAILS.url,
    timeout: serviceConfig.API_VERSION_DETAILS.timeout,
    method: 'GET'
  })
  return response.data
}

const getAnnotationsWidgetData = async (
  filters: WidgetAnnotationsQueryParams
): Promise<AnnotationsWidgetData> => {
  const baseQueryParams = { [LIMIT]: 5 }
  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_WIDGET_ANNOTATIONS.url}?${stringify(queryParams)}`
  })

  return response.data
}
const getAnnotationsWidgetDataById = async (
  datasourceId: string
): Promise<AnnotationsWidgetData> => {
  const response = await request({
    url: `${serviceConfig.API_WIDGET_ANNOTATIONS.url}?${DATA_SOURCE_ID}=${datasourceId}&${LIMIT}=5`,
    timeout: serviceConfig.API_WIDGET_ANNOTATIONS.timeout
  })
  return response.data
}

const getChannelsUsersCountByDataSourceId = async (
  datasourceId: string
): Promise<ITotalChannelsUsers> => {
  const response = await request({
    url: `${serviceConfig.API_SLACK_TOTAL_CHANNELS_USERS.url}/${datasourceId}`,
    timeout: serviceConfig.API_SLACK_TOTAL_CHANNELS_USERS.timeout
  }).catch((error) => {
    throw error
  })
  return response.data
}

const getViolationsByDataSourceId = async (
  datasourceId: string
): Promise<{ data: IViolations[]; total: number }> => {
  const response = await request({
    url: `${serviceConfig.API_SLACK_VIOLATIONS.url}?${DATA_SOURCE_ID}=${datasourceId}`,
    timeout: serviceConfig.API_SLACK_VIOLATIONS.timeout,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  }).catch((error) => {
    throw error
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return { data: response.data || [], total }
}

const getViolations = async (
  filters: IGetViolationsParams
): Promise<{ list: Violation[]; total: number }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_VIOLATIONS.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return { list: response.data, total }
}

const getUserEntitiesByDataSourceId = async (
  dataSourceId: string
): Promise<{ data: UserEntityBeingShared[]; total: number }> => {
  const response = await request({
    url: `${serviceConfig.API_ENTITIES.url}?${DATA_SOURCE_ID}=${dataSourceId}`,
    timeout: serviceConfig.API_ENTITIES.timeout,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  }).catch((error) => {
    throw error
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0

  return { data: response.data, total }
}

const getDuplicateRecordsCount = async (): Promise<{ count: number }> => {
  const response = await request({
    url: `${serviceConfig.API_DUPLICATE_RECORDS.url}`,
    timeout: serviceConfig.API_DUPLICATE_RECORDS.timeout
  })
  return response.data
}

const getFileTypeWidgetData = async (datasourceId: string): Promise<FileTypeWidgetData> => {
  const response = await request({
    url: `${serviceConfig.API_WIDGET_FILE_TYPE.url}?${DATA_SOURCE_ID}=${datasourceId}`,
    timeout: serviceConfig.API_WIDGET_FILE_TYPE.timeout
  })
  return response.data
}

const getTotalFilesCount = async (dataSourceId: string): Promise<{ total: number }> => {
  const response = await request({
    url: `${serviceConfig.API_DOCUMENTS.url}?${DATA_SOURCE_ID}=${dataSourceId}`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })
  const total = +response?.headers[FILTER_COUNT_HEADER] || 0

  return { total }
}

const getClassificationTemplates = async (): Promise<any> => {
  return classificationTemplates
}

const getClassificationAttributes = async (): Promise<any> => {
  return classificationTemplates
}

const getClassificationCountries = async (): Promise<any> => {
  return classificationCountries
}

const getDocuments = async (
  filters: IGetDocumentsParams
): Promise<{
  list: Document[]
  total: number
  risky: boolean
  sensitive: boolean
}> => {
  const page = filters.page || 1
  const baseQueryParams = {
    [OFFSET]: (page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters, anonymize: false }

  const response = await request({
    url: `${serviceConfig.API_DOCUMENTS.url}?${stringify(queryParams)
      .replaceAll('%3D', '=')
      .replaceAll('%26', '&')}`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return {
    list: response.data,
    total,
    risky: filters.isAtRisk || false,
    sensitive: filters.isSensitive || false
  }
}

const getDocumentsByClasses = async (
  filters?: DocumentClassCardsParams
): Promise<DocumentClassCards[]> => {
  const response = await request({
    url: `${serviceConfig.API_DOCUMENTS_CLASS_CARDS.url}?${stringify(filters || {})
      .replaceAll('%3D', '=')
      .replaceAll('%26', '&')}`
  })

  return response.data
}

const getClassificationsList = async (): Promise<Classification[]> => {
  const response = await request({
    url: serviceConfig.API_ANNOTATION_CLASSES.url,
    timeout: serviceConfig.API_ANNOTATION_CLASSES.timeout
  })
  return response.data
}

export interface CreateCustomClassification {
  class: string
  subclasses: string[]
  enabled: boolean
}

const createCustomClassification = async (
  params: CreateCustomClassification
): Promise<{ id: string }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_DOCUMENT_CLASSES.url,
    timeout: serviceConfig.API_DOCUMENT_CLASSES.timeout,
    data: {
      ...params,
      docType: ['document'] // TODO get list from dropdown
    }
  })
  return response.data
}

export type PatchCustomClassificationParams = Omit<CreateCustomClassification, 'class'> & {
  id: string
}
const patchCustomClassification = async (
  params: PatchCustomClassificationParams
): Promise<{ id: string }> => {
  const response = await request({
    method: 'PATCH',
    url: serviceConfig.API_DOCUMENT_CLASSES.url + `/${params.id}`,
    timeout: serviceConfig.API_DOCUMENT_CLASSES.timeout,
    data: {
      ...params,
      docType: ['document'] // TODO get list from dropdown
    }
  })
  return response.data
}

const getResidenciesList = async (
  filters: IGetResidenciesParams,
  is_limit?: boolean
): Promise<{ list: IResidency[]; total: number; risky: boolean; sensitive: boolean }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT
  }
  if (is_limit) {
    baseQueryParams[LIMIT] = LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_RESIDENCIES.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0

  return {
    list: response.data,
    total,
    risky: filters.isAtRisk || false,
    sensitive: filters.isSensitive || false
  }
}

const getDataLocations = async (): Promise<DataLocation[]> => {
  const response = await request({
    url: serviceConfig.API_DATA_LOCATIONS.url,
    timeout: serviceConfig.API_DATA_LOCATIONS.timeout
  })
  return response.data
}

const getFileTypesByEntityId = async (entityId: string): Promise<FileTypeWidgetData> => {
  const response = await request({
    url: `${serviceConfig.API_WIDGET_FILE_TYPES.url}?userEntityId=${entityId}`,
    timeout: serviceConfig.API_WIDGET_FILE_TYPES.timeout
  })
  return response.data
}

const getEntities = async (
  filters: IGetEntitiesParams
): Promise<{ list: UserEntity[]; total: number; risky: boolean; sensitive: boolean }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }
  const queryParams = { ...baseQueryParams, ...filters }
  const response = await request({
    url: `${serviceConfig.API_ENTITIES.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return {
    list: response.data,
    total,
    risky: filters.isAtRisk || false,
    sensitive: filters.isSensitive || false
  }
}

const getEntityDataSourcesCount = async (
  filters: IGetDocumentsParams
): Promise<{
  total: number
  gDrive: boolean
  gMail: boolean
  slack: boolean
  outLook: boolean
}> => {
  const response = await request({
    url: `${serviceConfig.API_DOCUMENTS.url}?${stringify({ ...filters })}`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return {
    total,
    slack: filters.datasourceType === DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.slack] || false,
    gMail: filters.datasourceType === DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.gmail] || false,
    outLook:
      filters.datasourceType === DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.outLook] || false,
    gDrive: filters.datasourceType === DATA_SOURCE_TYPE_API_MAP[DATA_SOURCE_TYPES.gdrive] || false
  }
}

const getConnectedApps = async (): Promise<ConnectedApps> => {
  return connectedApps
}

const getGDriveSummaryWidgetData = async (
  filters: GDriveSummaryQueryParams
): Promise<GDriveSummary> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_GDRIVE_SUMMARY_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getOneDriveSummaryWidgetData = async (
  filters: OneDriveSummaryQueryParams
): Promise<OneDriveSummary> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_ONEDRIVE_SUMMARY_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getSharePointSummaryWidgetData = async (
  filters: OneDriveSummaryQueryParams
): Promise<SharePointSummary> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_SHAREPOINT_SUMMARY_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}
const getAWSS3SummaryWidgetData = async (
  filters: AWSS3SummaryQueryParams
): Promise<AWSS3Summary> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_AWSS3_SUMMARY_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getMessagesWidgetData = async (
  filters: GDriveSummaryQueryParams
): Promise<MessagesSummary> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_IM_SUMMARY.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getGMailSummaryWidgetData = async (
  filters: GMailSummaryQueryParams
): Promise<GMailSummary> => {
  const response = await request({
    url: `${serviceConfig.API_MAIL_SUMMARY_WIDGET.url}?${stringify({ ...filters })}`
  })

  return response.data
}

const getOutlookSummaryWidgetData = async (
  filters: OutlookSummaryQueryParams
): Promise<OutlookSummary> => {
  const response = await request({
    url: `${serviceConfig.API_MAIL_SUMMARY_WIDGET.url}?${stringify({ ...filters })}`
  })

  return response.data
}

const getAlertsList = async (
  params: IGetAlertsListParams
): Promise<{ list: AlertInfoItem[]; total: number }> => {
  params
  // const response = await request({
  //   url: `${serviceConfig.API_ALERTS.url}?${stringify(params)}`
  // })

  // const total = +response?.headers[FILTER_COUNT_HEADER] || 0

  // return { list: response.data, total }
  return {
    list: alertsList,
    total: alertsList.length
  }
}

const getDatasourceUsersList = async (
  filters: IGetUsersParams
): Promise<{ list: User[]; total: number; risky: boolean; sensitive: boolean }> => {
  const baseQueryParams = {
    [OFFSET]: ((filters?.page || 1) - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_DATASOURCE_USERS.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return {
    list: response.data,
    total,
    risky: filters.isAtRisk || false,
    sensitive: filters.isSensitive || false
  }
}

const getUsersList = async (
  filters: IGetUsersParams
): Promise<{ list: IUser[]; total: number }> => {
  const baseQueryParams = {
    [OFFSET]: ((filters?.page || 1) - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_USERS.url}?${stringify(queryParams)}`
  })

  const total = +response?.headers[TOTAL_COUNT_HEADER] || 0
  return {
    list: response.data,
    total
  }
}

const getUserById = async (id: string): Promise<{ user: IUser }> => {
  const response = await request({
    url: `${serviceConfig.API_USERS.url}/${id}`
  })

  return {
    user: response.data
  }
}

const deleteUser = async (id: string): Promise<string> => {
  const response = await request({
    method: 'DELETE',
    url: `${serviceConfig.API_USERS.url}/${id}`,
    timeout: serviceConfig.API_USERS.timeout,
    data: { id }
  })
  return response.data
}

export interface UserAccess {
  accessLevel: string
  enabled?: boolean
}
export interface UserRole {
  name: string
  description: string
  accessLevels?: UserAccess[]
}
const getUserRolesList = async (): Promise<UserRole[]> => {
  const response = await request({
    url: `${serviceConfig.API_USER_ROLES.url}`
  })
  return response.data
}

export interface InviteUserParams {
  email_id: string
  roles?: {
    name: string
    access_levels: Array<UserAccess>
  }[]
}
const postInviteUser = async (params: InviteUserParams): Promise<string> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_INVITE_USER.url}`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    data: params
  })
  return response.data
}

const patchUser = async (data: UpdateUserParams): Promise<string> => {
  const { userId } = data
  delete data.userId
  const response = await request({
    method: 'PATCH',
    url: `${serviceConfig.API_USERS.url}/${userId}`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    data
  })
  return response.data
}

const getGDriveUsersWidgetData = async (
  filters: GDriveUsersWidgetsQueryParams
): Promise<GDriveUsersWidgets> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_GDRIVE_USERS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getGMailUsersWidgetData = async (
  filters: GMailUsersWidgetQueryParams
): Promise<GMailUsersWidget> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_MAIL_USERS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}
const getOutlookUsersWidgetData = async (
  filters: OutlookUsersWidgetQueryParams
): Promise<OutlookUsersWidget> => {
  const baseQueryParams = { [LIMIT]: 5 }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_MAIL_USERS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}
const getSlackMembersWidgetData = async (
  filters: SlackWidgetQueryParams
): Promise<SlackMembersWidgets> => {
  const queryParams = { ...filters }

  const response = await request({
    url: `${serviceConfig.API_SLACK_MEMBERS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}
const getSlackChannelsWidgetData = async (
  filters: SlackWidgetQueryParams
): Promise<SlackChannelsWidgets> => {
  const queryParams = { ...filters }

  const response = await request({
    url: `${serviceConfig.API_SLACK_CHANNELS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

export interface ImMessageItem extends ImMessage {
  attributes?: string[]
  entities?: Array<{
    id: string
    names: string[]
  }>
  attachments: Array<{
    type: string
    name: string
  }>
  userName?: string
  teamId?: string
  teamName?: string
  teamType?: CHANNEL_TYPES
}
const getImMessage = async (
  filters: IGetMessagesParams
): Promise<{ list: ImMessageItem[]; total: number }> => {
  const baseQueryParams = {
    [OFFSET]: (filters[PAGE] - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_IM_MESSAGES.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return { list: response.data.messages, total }
}

const getGroupsList = async (
  filters: IGetGroupsParams
): Promise<{ list: Group[]; total: number; risky: boolean; sensitive: boolean }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_GROUPS.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return {
    list: response.data,
    total,
    risky: filters.isAtRisk || false,
    sensitive: filters.isSensitive || false
  }
}

const getGDriveGroupsWidgetData = async (
  filters: GDriveGroupsWidgetsQueryParams
): Promise<GDriveGroupsWidgets> => {
  const baseQueryParams = { [LIMIT]: 5 }
  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_GDRIVE_GROUPS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getGmailGroupsWidgetData = async (
  filters: GDriveGroupsWidgetsQueryParams
): Promise<GDriveGroupsWidgets> => {
  const baseQueryParams = { [LIMIT]: 5 }
  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_GMAIL_GROUPS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getOutlookGroupsWidgetData = async (
  filters: GDriveGroupsWidgetsQueryParams
): Promise<GDriveGroupsWidgets> => {
  const baseQueryParams = { [LIMIT]: 5 }
  const queryParams = { ...baseQueryParams, ...filters }

  const response = await request({
    url: `${serviceConfig.API_OUTLOOK_GROUPS_WIDGET.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getAlertsChart = async (): Promise<Array<NamedTimeSeriesItem>> => {
  return alertsChart
}

const getDrivesWidget = async (filters: GDriveWidgetQueryParams): Promise<GDriveWidget> => {
  const queryParams = { ...filters }

  const response = await request({
    url: `${serviceConfig.API_WIDGET_DRIVES.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getSlackWorkspaceWidget = async (
  filters: SlackWidgetQueryParams
): Promise<SlackWorkspaceWidget> => {
  const queryParams = { ...filters }

  const response = await request({
    url: `${serviceConfig.API_SLACK_WORKSPACE.url}?${stringify(queryParams)}`
  })

  return response.data
}

const getSentimentAnalysisList = async (): Promise<Array<SentimentAnalysisItem>> => {
  return sentimentAnalysisList
}

const getAuditTrailList = async (): Promise<Array<AuditTrailItem>> => {
  return auditTrailList
}

const getAnomaliesList = async (): Promise<{ list: Array<AnomaliesInfoItem>; total: number }> => {
  return {
    list: anomaliesList,
    total: anomaliesList.length
  }
}

const getAnomaliesChart = async (): Promise<Array<NamedTimeSeriesItem>> => {
  return anomaliesChart
}

const getPolicies = async (): Promise<{
  total: number
  sensitive: number
  atRisk: number
  vector: string
}> => {
  return { total: 46, sensitive: 5, atRisk: 11, vector: 'increase' }
}

const postFileForFilter = async (data: FormData): Promise<{ id: string }> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_PROFILE_MAP.url}`,
    timeout: serviceConfig.API_PROFILE_MAP.timeout,
    data
  })

  return response.data
}

const getRules = async (): Promise<{ total: number }> => {
  return { total: 10 }
}

const getLocationsList = async (
  filters: IGetLocationsParams
): Promise<{ list: Location[]; total: number }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters }

  await request({
    url: `${serviceConfig.API_LOCATIONS.url}?${stringify(queryParams)}`
  })
  return { list: [], total: 0 }
}

const getConversations = async (documentId: string): Promise<ConversationMessage[]> => {
  const response = await request({
    url: `${serviceConfig.API_CONVERSATIONS.url}?documentId=${documentId}`,
    timeout: serviceConfig.API_CONVERSATIONS.timeout
  })
  return response.data
}

const getEmails = async (
  filters: IGetEmailParams
): Promise<{ list: Email[]; total: number; risky: boolean; sensitive: boolean }> => {
  const baseQueryParams = {
    [OFFSET]: (filters.page - 1) * LIMIT_DEFAULT,
    [LIMIT]: LIMIT_DEFAULT
  }

  const queryParams = { ...baseQueryParams, ...filters, anonymize: false }

  const response = await request({
    url: `${serviceConfig.API_MAILS.url}?${stringify(queryParams)}`,
    headers: { [FILTER_COUNT_HEADER]: 'null' }
  })

  const total = +response?.headers[FILTER_COUNT_HEADER] || 0
  return {
    list: response.data,
    total,
    risky: filters.isAtRisk || false,
    sensitive: filters.isSensitive || false
  }
}

const getEmailById = async (mailId: string): Promise<Email> => {
  const response = await request({
    url: `${serviceConfig.API_MAILS.url}/${mailId}`
  })

  return response.data
}

const postUserFeedback = async (
  documentId: string,
  data: AnnotationFeedbackParams
): Promise<GetTextLabels> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DOCUMENTS.url}/${documentId}/feedback`,
    timeout: serviceConfig.API_DOCUMENTS.timeout,
    data
  })
  return response.data
}

const postColumnClassifications = async (data: ColumnClassificationsParams): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_CLASSIFICATIONS_POST.url,
    timeout: serviceConfig.API_CLASSIFICATIONS_POST.timeout,
    data
  })
  return response.data
}

const postIdentityTable = async (data: IdentityTableParams): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_CLASSIFICATIONS_POST.url,
    timeout: serviceConfig.API_CLASSIFICATIONS_POST.timeout,
    data
  })
  return response.data
}

const deleteTableClassification = async (columnId: string): Promise<string> => {
  await request({
    method: 'DELETE',
    url: `${serviceConfig.API_CLASSIFICATIONS.url}/${columnId}`,
    timeout: serviceConfig.API_CLASSIFICATIONS.timeout
  })
  return columnId
}

const getAttributesWidgetByEntity = async (
  params: IGetAttributesParams
): Promise<AttributeWidgetByEntityItem[]> => {
  params
  // const response = await request({
  //   url: `${serviceConfig.API_ATTRIBUTES_WIDGET.url}?${stringify(params)}`
  // })
  // return response.data
  return []
}

const getDataSourcesWidgetByEntity = async (
  params: IGetAttributesParams
): Promise<InstancesByDatasourceWidgetItem[]> => {
  params
  // const response = await request({
  //   url: `${serviceConfig.API_DATA_SOURCES_WIDGET.url}?${stringify(params)}`
  // })
  // return response.data
  return []
}

const deleteTableCluster = async (parentTableId: string): Promise<string> => {
  await request({
    method: 'DELETE',
    url: `${serviceConfig.API_CLASSIFICATIONS_IDENTITY.url}/${parentTableId}`,
    timeout: serviceConfig.API_CLASSIFICATIONS_IDENTITY.timeout
  })
  return parentTableId
}

const getMSTeams = async (
  params: MSTeamsParams
): Promise<{ list: MSTeamsTeam[]; total: number }> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_MS_TEAMS.url}`,
    data: params
  })
  return { list: response.data?.teams, total: response.data?.teams?.length || 0 }
}

const getMSTeamsChannels = async (
  params: MSTeamsParams,
  teamId: string
): Promise<{ list: MSTeamsTeam[]; total: number }> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_MS_TEAMS.url}/${teamId}/channels`,
    data: params
  })
  return {
    list: response.data?.teamChannels?.map((channel) => ({ ...channel, teamId })),
    total: response.data?.teamChannels?.length || 0
  }
}

const getSlackChannels = async (
  params: SlackChannelParams
): Promise<{ list: SlackChannel[]; total: number }> => {
  const response = await request({
    url: `${serviceConfig.API_SLACK_CHANNELS.url}?${stringify(params)}`
  })
  return { list: response.data, total: response.data?.length || 0 }
}

const getJiraProjects = async (
  data: JiraProjectParams
): Promise<{ list: JiraProject[]; total: number }> => {
  const response = await request({ method: 'POST', url: serviceConfig.API_JIRA_PROJECTS.url, data })
  return { list: response.data, total: response.data?.length || 0 }
}

const getAwsS3Buckets = async (
  data: AwsS3BucketsParams
): Promise<{ list: AwsS3Bucket[]; total: number }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_AWS_S3_BUCKETS.url,
    timeout: serviceConfig.API_AWS_S3_BUCKETS.timeout,
    data
  })
  return {
    list: response.data,
    total: response.data?.length || 0
  }
}
const getAwsS3BucketsNotifications = async (
  data: AwsS3BucketsNotificationsParams
): Promise<{ list: AwsS3Bucket[] }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_AWS_EVENT_BRIDGE_NOTIFICATIONS.url,
    timeout: serviceConfig.API_AWS_EVENT_BRIDGE_NOTIFICATIONS.timeout,
    data
  })
  return { list: response.data.buckets }
}

const getAzureBlobSubscriptions = async (
  data: AzureBlobSubscriptionParams
): Promise<AzureBlobSubscription[]> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_AZURE_SUBSCRIPTIONS.url,
    timeout: serviceConfig.API_AZURE_SUBSCRIPTIONS.timeout,
    data
  })
  return response.data
}
const getAzureBlobStorageAccounts = async (
  data: AzureBlobStorageAccountParams
): Promise<AzureBlobStorageAccount[]> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_AZURE_STORAGE_ACCOUNTS.url,
    timeout: serviceConfig.API_AZURE_STORAGE_ACCOUNTS.timeout,
    data
  })
  return response.data
}
const getAzureBlobContainers = async (
  data: AzureBlobContainerParams
): Promise<AzureBlobContainer[]> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_AZURE_BLOB_CONTAINERS.url,
    timeout: serviceConfig.API_AZURE_BLOB_CONTAINERS.timeout,
    data
  })
  return response.data
}

const downloadFile = async (
  data: DownloadListParams | DownloadPdfParams
): Promise<{ data: string; headers: any }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_REPORTS.url,
    timeout: serviceConfig.API_REPORTS.timeout,
    data
  })
  return { data: response.data, headers: response.headers }
}

const getFeatureFlags = async (): Promise<any> => {
  try {
    const response = await request({
      url: `${serviceConfig.API_FEATURE_FLAGS.url}`
    })
    return response.data
  } catch (error: any) {
    store.dispatch(logoutAction())
    return API_ERROR_STATUS.UNHANDLED_ERROR
  }
}

const postCreatePdf = async (
  data: FormData,
  progressHandler: (event: any) => void
): Promise<{ data: Buffer; header: AxiosResponseHeaders }> => {
  const response = await request({
    method: 'POST',
    url: '/pdf',
    data,
    responseType: 'arraybuffer',
    onDownloadProgress: progressHandler
  })
  return { data: response.data, header: response.headers }
}

const generateDeltaSummaryReport = async (
  progressHandler: (event: any) => void
): Promise<{
  data: Buffer
  header: AxiosResponseHeaders
}> => {
  const response = await request({
    method: 'GET',
    responseType: 'arraybuffer',
    onDownloadProgress: progressHandler,
    url: `${serviceConfig.API_GENERATE_DELTA_SUMMARY_REPORT.url}`,
    timeout: serviceConfig.API_GENERATE_DELTA_SUMMARY_REPORT.timeout
  })
  return { data: response.data, header: response.headers }
}

const postSendReportToEmail = async (data: FormData, module: 'dsar' | 'ropa'): Promise<any> => {
  try {
    const res = await request({
      method: 'POST',
      url: '/pdf-to-email/' + module,
      data
    })

    return { status: res.status }
  } catch (e) {
    return { status: 500 }
  }
}

const downloadEntityJson = async (data: DownloadJsonParams): Promise<{ data: string }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_REPORTS.url,
    timeout: serviceConfig.API_REPORTS.timeout,
    data: {
      reportFormat: DownloadReportFormats.json,
      reportType: DownloadReportTypes.entityList,
      parameters: [
        {
          key: 'DATASOURCE_ID',
          values: data.datasourceId
        },
        {
          key: 'ENTITY_ID',
          values: data.entityId
        }
      ]
    }
  })
  return { data: response.data }
}

const downloadEntityCSV = async (
  data: DownloadListParams
): Promise<{ data: string; headers: any }> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_REPORTS.url,
    timeout: serviceConfig.API_REPORTS.timeout,
    data
  })
  return { data: response.data, headers: response.headers }
}

const downloadReportAttachment = async (
  notificationId: string,
  attachmentId: string
): Promise<{ data: Blob }> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_REPORTS_ATTACHMENT.url}`,
    timeout: serviceConfig.API_REPORTS_ATTACHMENT.timeout,
    data: {
      notificationId,
      attachmentId
    },
    responseType: 'blob'
  })

  return { data: response.data }
}

const getErasedEntities = async (): Promise<{ list: ErasedEntity[]; total: number }> => {
  const baseQueryParams = { [OFFSET]: 0, [LIMIT]: 500 }

  const queryParams = { ...baseQueryParams }

  const response = await request({
    url: `${serviceConfig.API_ERASED_ENTITIES.url}?${stringify(queryParams)}`
  })
  return { list: response.data, total: response.data?.length || 0 }
}

const postErasedEntity = async (data: CrateErasedEntityParams): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_ERASED_ENTITIES.url,
    timeout: serviceConfig.API_ERASED_ENTITIES.timeout,
    data
  })
  return response.data
}
const putErasedEntity = async (data: UpdateErasedEntityParams): Promise<void> => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_ERASED_ENTITIES.url + '/' + data.id,
    timeout: serviceConfig.API_ERASED_ENTITIES.timeout,
    data: { attributes: data.attributes }
  })
  return response.data
}

const postDsrAdditionalAttributes = async ({
  files,
  requestId
}: UploadTicketAttributesParams): Promise<string[]> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DSR_TICKET_ATTRIBUTES.url}`,
    timeout: serviceConfig.API_DSR_TICKET_ATTRIBUTES.timeout,
    data: {
      requestId,
      files
    }
  })

  return response.data?.fileIds || []
}

const detectAttributesFromFile = async (file: File): Promise<AttributeDetectedSummary[]> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DETECT_ATTRIBUTES.url}`,
    timeout: serviceConfig.API_DETECT_ATTRIBUTES.timeout,
    data: {
      file: {
        fileContent: ((await transformFileToDataUrl(file)) as string).replace(/^.*;base64,/, ''),
        fileName: file.name,
        fileMimeType: file.type
      }
    }
  })
  return response.data?.attributeList || []
}

const postDsrSubmittedForm = async (submittedValues: any): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DSR_REQUEST.url}`,
    timeout: serviceConfig.API_DSR_REQUEST.timeout,
    data: submittedValues
  })

  return response.data
}

const getDSRAssigneeEmails = async (): Promise<any> => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_DSR_REQUEST_ASSIGNEE_EMAILS.url}`,
    timeout: serviceConfig.API_DSR_REQUEST_ASSIGNEE_EMAILS.timeout
  })

  return response.data
}

const getDSRTicketAssigneeEmails = async (): Promise<any> => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_DSR_TICKETS_ASSIGNEE_EMAILS.url}`,
    timeout: serviceConfig.API_DSR_TICKETS_ASSIGNEE_EMAILS.timeout
  })

  return response.data
}

const getAttachmentById = async (id: string) => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_DSR_ATTACHMENT.url}/${id}`,
    timeout: serviceConfig.API_DSR_ATTACHMENT.timeout,
    responseType: 'blob'
  })
  openBlobInNewTab(response.data)
}

const getWebAppUrl = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_DSR_WEB_APP.url,
    timeout: serviceConfig.API_DSR_WEB_APP.timeout
  })
  return response.data
}

const getCookieConsentS3Bucket = async (params) => {
  const response = await request({
    method: 'GET',
    url:
      serviceConfig.API_COOKIE_CONSENT_S3_BUCKET.url +
      (params.version ? `?version=${params.version}` : ''),
    timeout: serviceConfig.API_COOKIE_CONSENT_S3_BUCKET.timeout
  })
  return response.data
}

const getCookieConsentVersions = async ({
  domainId,
  version
}: Partial<{
  domainId: string
  version: string
}>) => {
  const searchParams = new URLSearchParams()
  if (domainId) searchParams.append('domain_id', domainId)
  if (version) searchParams.append('version', version)
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_VERSIONS.url}?${searchParams.toString()}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_VERSIONS.timeout
  })
  return response.data
}

const getCookieConsentBanners = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_BANNER.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_BANNER.timeout
  })
  return response.data
}

const getCookieConsentBannerById = async (id: string) => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_BANNER.url + '/' + id,
    timeout: serviceConfig.API_COOKIE_CONSENT_BANNER.timeout
  })
  return response.data
}

const deleteCookieConsentBannerById = async (id: string) => {
  const response = await request({
    method: 'DELETE',
    url: serviceConfig.API_COOKIE_CONSENT_BANNER.url + '/' + id,
    timeout: serviceConfig.API_COOKIE_CONSENT_BANNER.timeout
  })
  return response.data
}

const postCookieConsentBanner = async (data: CookieConsentBanner) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_BANNER.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_BANNER.timeout,
    data
  })
  return response.data
}
const putCookieConsentBanner = async (data: CookieConsentBanner) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_BANNER.url + '/' + data.id,
    timeout: serviceConfig.API_COOKIE_CONSENT_BANNER.timeout,
    data
  })
  return response.data
}

const getCookieConsentDomains = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}

const getSubdomains = async (domain: string) => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/get-subdomains?domain=' + domain,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}

const putCookieConsentDomain = async (data: CookieConsentDomain) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/' + data.id,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout,
    data
  })
  return response.data
}
const getCookieConsentLogsByDomainId = async ({
  domainId,
  page
}: FetchDomainConsentLogParams): Promise<{ list: ConsentLog[]; total: number }> => {
  const queryParams = page
    ? {
        [OFFSET]: (page - 1) * LIMIT_DEFAULT,
        [LIMIT]: LIMIT_DEFAULT,
        sortBy: 'updatedAt',
        sortAsc: false
      }
    : {}
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_DOMAIN.url}/${domainId}/consent?${stringify(
      queryParams
    )}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return { list: response.data.data, total: response.data.totalCount }
}

const getCookieConsentByCategory = async (params: {
  domainId?: string
  startDate?: string
  endDate?: string
}) => {
  const { startDate, endDate, domainId } = params
  const path = domainId ? `${domainId}/consent` : 'consent'
  const searchParams = new URLSearchParams()
  if (startDate && endDate) {
    searchParams.append('startDate', startDate)
    searchParams.append('endDate', endDate)
  }
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_DOMAIN.url}/${path}?${searchParams.toString()}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}

const getCookieConsentByDomain = async (params: { startDate?: string; endDate?: string }) => {
  const { startDate, endDate } = params
  const searchParams = new URLSearchParams()
  if (startDate && endDate) {
    searchParams.append('startDate', startDate)
    searchParams.append('endDate', endDate)
  }
  const response = await request({
    method: 'GET',
    url: `${
      serviceConfig.API_COOKIE_CONSENT.url
    }consent/getConsentInfoByEventType?${searchParams.toString()}`,
    timeout: serviceConfig.API_COOKIE_CONSENT.timeout
  })
  return response.data
}

const getCookieConsentDomainById = async (id: string) => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/' + id,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const deleteCookieConsentDomainById = async (id: string) => {
  const response = await request({
    method: 'DELETE',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/' + id,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const postCookieConsentDomain = async (data: CookieConsentDomain) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout,
    data
  })
  return response.data
}
const validateCookieConsentDomains = async (data: CookieConsentDomainValidationParams) => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_COOKIE_CONSENT_DOMAIN.url}/validate`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout,
    data
  })
  return response.data
}
const getCookieCategoriesByDomainId = async (id: string) => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_DOMAIN.url}/${id}/category`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const postDomainCookieCategory = async (data: CookieCategory) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_COOKIE_CATEGORY.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_COOKIE_CATEGORY.timeout,
    data
  })
  return response.data
}
const putDomainCookieCategory = async (data: CookieCategory) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_COOKIE_CATEGORY.url + '/' + data.id,
    timeout: serviceConfig.API_COOKIE_CONSENT_COOKIE_CATEGORY.timeout,
    data
  })
  return response.data
}
const deleteDomainCookieCategory = async (id: string) => {
  const response = await request({
    method: 'DELETE',
    url: serviceConfig.API_COOKIE_CONSENT_COOKIE_CATEGORY.url + '/' + id,
    timeout: serviceConfig.API_COOKIE_CONSENT_COOKIE_CATEGORY.timeout
  })
  return response.data
}

const getCookieDistribution = async (domainId?: string) => {
  const path = serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/cookie-distribution'
  const params = new URLSearchParams()
  if (domainId) {
    params.append('domainId', domainId)
  }
  const response = await request({
    method: 'GET',
    url: path + '?' + params.toString(),
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getCookiesByDomainId = async (params: FetchDomainCookiesParams) => {
  const queryParams = params.page
    ? {
        [OFFSET]: ((params.page || 1) - 1) * LIMIT_DEFAULT,
        [LIMIT]: LIMIT_DEFAULT
      }
    : {}

  if (params.filters?.length) {
    params.filters?.map((filter) => {
      queryParams[filter.key] = filter.values?.join(',')
    })
  }
  const queryString = `?${stringify(queryParams)}`
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_DOMAIN.url}/${params.domainId}/cookie${queryString}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })

  return { list: response.data.data, total: response.data.totalCount }
}

const getCookiesConsentByRegion = async (params: {
  domainId?: string
  startDate?: string
  endDate?: string
}) => {
  const { startDate, endDate, domainId } = params
  const searchParams = new URLSearchParams()
  if (domainId) searchParams.append('domain_id', domainId)
  if (startDate && endDate) {
    searchParams.append('startDate', startDate)
    searchParams.append('endDate', endDate)
  }
  const response = await request({
    method: 'GET',
    url:
      serviceConfig.API_COOKIE_CONSENT_REGION.url +
      '/getConsentsByRegion?' +
      searchParams.toString(),
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout
  })
  return response.data
}

const postDomainCookie = async (data: Cookie) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_COOKIE.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_COOKIE.timeout,
    data
  })
  return response.data
}
const putDomainCookie = async (data: Cookie) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_COOKIE.url + '/' + data.id,
    timeout: serviceConfig.API_COOKIE_CONSENT_COOKIE.timeout,
    data
  })
  return response.data
}
const deleteDomainCookie = async (id: string) => {
  const response = await request({
    method: 'DELETE',
    url: serviceConfig.API_COOKIE_CONSENT_COOKIE.url + '/' + id,
    timeout: serviceConfig.API_COOKIE_CONSENT_COOKIE.timeout
  })
  return response.data
}
const saveConfirmedDomainCookies = async (data: { cookies: Cookie[] }) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/cookies',
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout,
    data
  })
  return response.data
}

const postScanDomain = async (id: string) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + `/${id}/scan`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getCookieConsentScanHistory = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/scan/stats',
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getDomainScanHistory = async (id: string) => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + `/${id}/scan/stats`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getDomainScanDataByScanId = async (domainId: string, scanId: string) => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + `/${domainId}/scan/${scanId}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const approveDomainScanData = async (scanId: string) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_SCAN.url + `/${scanId}/verify`,
    timeout: serviceConfig.API_COOKIE_CONSENT_SCAN.timeout
  })
  return response.data
}

const getCookieConsentScanStats = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/scan/stats',
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}

const getCookieConsentDomainsCounters = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/count',
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getCookieConsentCount = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_DOMAIN.url + '/consent',
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getCookieConsentCountsByDomainId = async (id: string) => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_DOMAIN.url}/${id}/count`,
    timeout: serviceConfig.API_COOKIE_CONSENT_DOMAIN.timeout
  })
  return response.data
}
const getDomainsScanHistory = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_SCAN.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_SCAN.timeout
  })
  return response.data
}

const getCookieConsentRegions = async (params?: CookieConsentRegionParams) => {
  const domainQuery = params?.domainId ? `?domainId=${params.domainId}` : ''

  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_REGION.url}/getRegions${domainQuery}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout
  })
  return response.data
}

const postCookieConsentRegion = async (data: UpdateCookieConsentRegionParams) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_REGION.url + '/createRegion',
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout,
    data
  })
  return response.data
}

const testCookieConsentS3Connection = async (data: CookieConsentS3TestConnectionParams) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_S3_TEST_CONNECTION.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_S3_TEST_CONNECTION.timeout,
    data
  })
  return response.data
}

const putCookieConsentRegion = async (data: UpdateCookieConsentRegionParams) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_REGION.url + `/updateRegion/${data.id}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout,
    data
  })
  return response.data
}

const deleteCookieConsentRegion = async (id: string): Promise<void> => {
  const response = await request({
    method: 'DELETE',
    url: `${serviceConfig.API_COOKIE_CONSENT_REGION.url}/deleteRegion/${id}`,
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout
  })
  return response.data
}

const putRegionBannerIds = async (data: CookieConsentRegionBannerIdsParams) => {
  const response = await request({
    method: 'PUT',
    url: serviceConfig.API_COOKIE_CONSENT_REGION.url + '/updateRegionBannerIds',
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout,
    data
  })
  return response.data
}

const getCookieConsentCountries = async () => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_COOKIE_CONSENT_REGION.url}/getCountries`,
    timeout: serviceConfig.API_COOKIE_CONSENT_REGION.timeout
  })
  return response.data
}

const getCookieConsentHostingSettings = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_CONFIG.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_CONFIG.timeout
  })
  return response.data
}

const postCookieConsentHostingSettings = async (data: UpdateCookieConsentHostingSettingsParams) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_COOKIE_CONSENT_CONFIG.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_CONFIG.timeout,
    data
  })
  return response.data
}

const getCookieConsentHostingConfigured = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_HOSTING_CONFIGURED.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_HOSTING_CONFIGURED.timeout
  })
  return response.data
}

const getRetryUploadCookieConsentConfig = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_UPLOAD_CONFIG.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_UPLOAD_CONFIG.timeout
  })
  return response.data
}
const getCookieConsentConfigStatus = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_CONFIG_STATUS.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_CONFIG_STATUS.timeout
  })
  return response.data
}

const incrementCookieConsentConfigVersion = async () => {
  const response = await request({
    method: 'GET',
    url: serviceConfig.API_COOKIE_CONSENT_CONFIG_INCREMENT_VERSION.url,
    timeout: serviceConfig.API_COOKIE_CONSENT_CONFIG_INCREMENT_VERSION.timeout
  })
  return response.data
}

const testDocumentLabelProviderConnection = async (
  data: DocumentLabelProvider
): Promise<{ message: string }> => {
  const response = await request({
    url: `${serviceConfig.API_DIAGNOSTICS.url}/label-set/test-connection`,
    timeout: serviceConfig.API_DIAGNOSTICS.timeout,
    method: 'POST',
    data: {
      configuration: data
    }
  })
  return response.data
}

function appendAuditLogsFilterParams(searchParams: URLSearchParams, filters: AuditLogsFilters) {
  if (filters?.action) {
    searchParams.append('search', 'action')
    filters.action.forEach((action) => searchParams.append('fields', action))
  } else if (filters?.module) {
    searchParams.append('search', 'module')
    filters.module.forEach((module) => searchParams.append('fields', module))
  } else if (filters?.status) {
    searchParams.append('search', 'status')
    filters.status.forEach((status) => searchParams.append('fields', status))
  } else if (filters?.attributeNames) {
    filters.attributeNames.forEach((attributeName) =>
      searchParams.append('attributeNames', attributeName)
    )
  }

  if (filters.startDate) {
    searchParams.append('startDate', filters.startDate)
  }

  if (filters.endDate) {
    searchParams.append('endDate', filters.endDate)
  }
}

type getAuditLogsParams = {
  page: number
  pageSize: number
  searchTerm: string
  abortSignal?: AbortSignal
  filters?: AuditLogsFilters
}

type DateRangeFilters = {
  startDate: string
  endDate: string
}

export type AuditLogsFilters = Partial<
  AuditLogsMapping &
    DateRangeFilters & {
      attributeNames: string[]
    }
>

export async function getAuditLogs({
  page,
  pageSize,
  searchTerm,
  abortSignal,
  filters
}: getAuditLogsParams): Promise<AuditLogsResponse> {
  const searchParams = new URLSearchParams(`page=${page}&size=${pageSize}`)

  if (searchTerm) {
    searchParams.append('search', 'email')
    searchParams.append('fields', searchTerm)
  } else if (filters) {
    appendAuditLogsFilterParams(searchParams, filters)
  }

  const response = await request({
    url: `${serviceConfig.API_AUDIT_LOGS.url}?${searchParams.toString()}`,
    timeout: serviceConfig.API_AUDIT_LOGS.timeout,
    signal: abortSignal
  })

  return response.data as AuditLogsResponse
}

export async function getAuditLogsCsv({
  page,
  pageSize,
  searchTerm,
  abortSignal,
  filters
}: getAuditLogsParams): Promise<{ data: string; filename: string }> {
  const searchParams = new URLSearchParams(`page=${page}&size=${pageSize}`)

  if (searchTerm) {
    searchParams.append('search', 'email')
    searchParams.append('fields', searchTerm)
  } else if (filters) {
    appendAuditLogsFilterParams(searchParams, filters)
  }

  const response = await request({
    url: `${serviceConfig.API_AUDIT_LOGS_DOWNLOAD_CSV.url}?${searchParams.toString()}`,
    timeout: serviceConfig.API_AUDIT_LOGS_DOWNLOAD_CSV.timeout,
    signal: abortSignal
  })

  return { data: response.data, filename: getFilenameFromHeaders(response.headers) }
}

export async function getAuditLogsMapping() {
  const response = await request({
    url: serviceConfig.API_AUDIT_LOGS_MAPPING.url,
    timeout: serviceConfig.API_AUDIT_LOGS_MAPPING.timeout
  })

  return response.data as AuditLogsMapping
}

const getDocAnalyticsStatus = async ({ datasourceId }: FetchDocAnalyticsStatusParams) => {
  const response = await request({
    method: 'GET',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${datasourceId}/doc_analytics/status`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })
  return { status: response.data.status, lastUpdatedOn: response.data['lastSyncedAt'] }
}

const runDocAnalytics = async ({ datasourceId }: TriggerDocAnalyticsParams) => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${datasourceId}/doc_analytics/trigger`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })
  return response.data
}

const getDocAnalyticsFileTypeFilterList = async ({
  datasourceId,
  skipped
}: FileTypeFilterParams) => {
  let url = `${serviceConfig.API_DOC_ANALYTICS.url}/${datasourceId}/data/object_count_grouped_by_extension`

  if (skipped) {
    url += '?skipped=true'
  }
  const response = await request({
    method: 'GET',
    url,
    timeout: serviceConfig.API_DOC_ANALYTICS.timeout
  })
  return response.data
}

const getDocAnalyticsFileSizeList = async ({ datasourceId, skipped }: DocAnalyticsWidgetParams) => {
  let url = `${serviceConfig.API_DOC_ANALYTICS.url}/${datasourceId}/data/object_count_grouped_by_size_category`

  if (skipped) {
    url += '?skipped=true'
  }
  const response = await request({
    method: 'GET',
    url,
    timeout: serviceConfig.API_DOC_ANALYTICS.timeout
  })
  return response.data
}

const getDocAnalyticsLastUpdatedList = async ({
  datasourceId,
  skipped
}: DocAnalyticsWidgetParams) => {
  let url = `${serviceConfig.API_DOC_ANALYTICS.url}/${datasourceId}/data/object_count_grouped_by_last_modified_date_category`

  if (skipped) {
    url += '?skipped=true'
  }
  const response = await request({
    method: 'GET',
    url,
    timeout: serviceConfig.API_DOC_ANALYTICS.timeout
  })
  return response.data
}

const postDomainNotificationSettings = async (data) => {
  const response = await request({
    method: 'POST',
    url: serviceConfig.API_DOMAIN_NOTIFICATION_SETTINGS.url,
    timeout: serviceConfig.API_DOMAIN_NOTIFICATION_SETTINGS.timeout,
    data
  })
  return response.data
}

const postPauseDatasourceScan = async (dataSourceId) => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${dataSourceId}/pause`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })
  return response.data
}

const postResumeDatasourceScan = async (dataSourceId) => {
  const response = await request({
    method: 'POST',
    url: `${serviceConfig.API_DATA_SOURCES.url}/${dataSourceId}/resume`,
    timeout: serviceConfig.API_DATA_SOURCES.timeout
  })
  return response.data
}

const validateRegexAndContext = async ({
  text,
  regexValues,
  contextWords
}: {
  text: string
  regexValues: string[]
  contextWords?: string[]
}): Promise<RegexValidationResponse> => {
  try {
    const systemValues = Object.keys(SYSTEM_DATA_TYPES)
    const patterns = regexValues
      .filter((val) => !!val)
      .map((value) => ({
        [systemValues.includes(value) ? 'type' : 'value']: value
      }))
    const context_words = contextWords?.length
      ? contextWords
          ?.filter((val) => !!val)
          ?.map((value) => ({
            value
          }))
      : []
    const payload = {
      text,
      patterns,
      context_words
    }
    const response = await request({
      method: 'POST',
      url: serviceConfig.API_VALIDATE_REGEX.url,
      timeout: serviceConfig.API_VALIDATE_REGEX.timeout,
      data: payload
    })
    return response.data
  } catch (error: any) {
    return error?.message
  }
}

export default {
  getDocumentById,
  getFileByDocumentId,
  postTemplateFile,
  getDataSourceById,
  getDataAnalyticsById,
  deleteDataSourceById,
  postDatasource,
  postDatasourceV2,
  postExchangeDatasource,
  putDatasourceById,
  deleteProjectById,
  testConnection,
  fetchOauthRedirectUrl,
  postConnectionStats,
  getAnnotationsWidgetData,
  getAnnotationsWidgetDataById,
  getDuplicateRecordsCount,
  getUserEntitiesByDataSourceId,
  putDocumentById,
  deleteTemplate,
  getTemplates,
  getTemplateById,
  postTextLabels,
  getFileTypesByEntityId,
  getClassificationTemplates,
  getClassificationAttributes,
  getClassificationCountries,
  getDocuments,
  getDocumentsByClasses,
  getClassificationsList,
  createCustomClassification,
  patchCustomClassification,

  getVersionDetails,
  getIdentifiers,
  getEntities,
  getFileTypeWidgetData,
  getAuthProviders,
  getAuthProviderByName,
  deleteAuthProvider,
  createAuthProvider,
  updateAuthProvider,
  logout,
  getResidenciesList,
  getDataLocations,
  getTotalFilesCount,
  getChannelsUsersCountByDataSourceId,
  getViolationsByDataSourceId,
  getConnectedApps,
  postAnonymizeText,
  postAnonymize,
  getAlertsList,
  getAlertsChart,
  getDrivesWidget,
  getAuditTrailList,
  getAnomaliesList,
  getAnomaliesChart,
  getSentimentAnalysisList,
  getPolicies,
  getRules,
  getGDriveSummaryWidgetData,
  getGDriveUsersWidgetData,
  getSharePointSummaryWidgetData,
  getGDriveGroupsWidgetData,
  getAWSS3SummaryWidgetData,
  getUsersList,
  getDatasourceUsersList,
  getGroupsList,
  getLocationsList,
  getMessagesWidgetData,
  getSlackWorkspaceWidget,
  getSlackMembersWidgetData,
  getSlackChannelsWidgetData,
  getViolations,
  getImMessage,
  getEmails,
  getGMailSummaryWidgetData,
  getGMailUsersWidgetData,
  getOutlookSummaryWidgetData,
  getOutlookUsersWidgetData,
  getConversations,
  getEmailById,
  postUserFeedback,
  getGmailGroupsWidgetData,
  getOutlookGroupsWidgetData,
  getEntityDataSourcesCount,
  postFileForFilter,
  getElasticSearchIndicesById,
  getElasticSearchIndexFieldsById,
  updateElasticSearchIndexFieldsById,
  getOneDriveSummaryWidgetData,
  postColumnClassifications,
  postIdentityTable,
  deleteTableClassification,
  getUserInfo,
  getAttributesWidgetByEntity,
  getDataSourcesWidgetByEntity,
  patchDatasourceById,
  patchDatasourceByIdV2,
  deleteTableCluster,
  getSlackChannels,
  getJiraProjects,
  getAwsS3Buckets,
  getAwsS3BucketsNotifications,
  postDsrFile,
  postInviteUser,
  getUserRolesList,
  getUserById,
  patchUser,
  deleteUser,
  getMailGroups,
  getMailMembers,
  testSmtpConfiguration,
  saveSmtpConfiguration,
  getSmtpConfiguration,
  getToken,
  getDrives,
  downloadFile,
  getSharePointSites,
  postCreatePdf,
  postSendReportToEmail,
  downloadEntityJson,
  downloadEntityCSV,
  downloadReportAttachment,
  getErasedEntities,
  postErasedEntity,
  putErasedEntity,
  getAttachmentById,
  postDsrAdditionalAttributes,
  getWebAppUrl,
  getCookieConsentS3Bucket,
  getCookieConsentVersions,
  testCookieConsentS3Connection,
  postDsrSubmittedForm,
  detectAttributesFromFile,
  setUserLocale,
  postBigQueryProjects,
  postLookerProjects,
  postDatabricksCatalogue,
  getDSRAssigneeEmails,
  getDSRTicketAssigneeEmails,

  getCookieConsentBanners,
  getCookieConsentBannerById,
  deleteCookieConsentBannerById,
  postCookieConsentBanner,
  putCookieConsentBanner,
  getCookieConsentDomains,
  getSubdomains,
  getCookieConsentDomainById,
  deleteCookieConsentDomainById,
  postCookieConsentDomain,
  getCookieConsentLogsByDomainId,
  putCookieConsentDomain,
  postScanDomain,
  validateCookieConsentDomains,
  approveDomainScanData,
  getCookieDistribution,
  getCookiesByDomainId,
  getCookiesConsentByRegion,
  postDomainCookie,
  putDomainCookie,
  deleteDomainCookie,
  saveConfirmedDomainCookies,
  getCookieConsentByCategory,
  getCookieConsentByDomain,

  getCookieCategoriesByDomainId,
  postDomainCookieCategory,
  putDomainCookieCategory,
  deleteDomainCookieCategory,
  getDomainScanHistory,
  getDomainScanDataByScanId,
  getCookieConsentDomainsCounters,
  getCookieConsentCount,
  getCookieConsentCountsByDomainId,
  getCookieConsentScanHistory,
  getDomainsScanHistory,
  getCookieConsentScanStats,
  getCookieConsentRegions,
  postCookieConsentRegion,
  putCookieConsentRegion,
  deleteCookieConsentRegion,
  getCookieConsentCountries,
  putRegionBannerIds,

  getCookieConsentHostingSettings,
  postCookieConsentHostingSettings,
  getCookieConsentHostingConfigured,
  getRetryUploadCookieConsentConfig,
  incrementCookieConsentConfigVersion,
  getCookieConsentConfigStatus,

  getAzureBlobSubscriptions,
  getAzureBlobStorageAccounts,
  getAzureBlobContainers,

  testDocumentLabelProviderConnection,
  saveReportConfiguration,
  getReportConfiguration,
  deleteReportConfiguration,
  getMSTeams,
  getMSTeamsChannels,
  getOrgDomains,

  getFeatureFlags,
  generateDeltaSummaryReport,

  getDocAnalyticsStatus,
  runDocAnalytics,
  getDocAnalyticsFileTypeFilterList,
  getDocAnalyticsFileSizeList,
  getDocAnalyticsLastUpdatedList,

  postDomainNotificationSettings,

  postPauseDatasourceScan,
  postResumeDatasourceScan,

  validateRegexAndContext
}
